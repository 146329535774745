<template>
  <section class="social-media" v-if="feature_toggles.social !== false">
    <social_media_accounts v-if="permissions.social.sections.accounts.module && social_action_type === accounts"/>
    <social_media_posts v-else-if="permissions.social.sections.posts.module && social_action_type === posts"/>
    <social_media_ads v-else-if="permissions.social.sections.advertising.module && social_action_type === advertising"/>
    <analytics v-else-if="permissions.social.sections.marketing_analytics.module && social_action_type === marketing_analytics"/>

    <p v-else class="md-headline no-permissions">
      {{ $translate("no_permissions_message") }}
    </p>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex"
import is_user_in_global_admin from "../../../Shared/methods/is_user_in_global_admin"
import {
  accounts, posts, ADMIN, SOCIAL_STORE, USER_STORE, advertising, marketing_analytics
} from "../../constants/others_constants"
import { FETCH_SOCIAL_MEDIA } from "../../stores/Admin/social/constants"

export default {
  components: {
    social_media_accounts: () => import("../utils/social/social_media_accounts"),
    social_media_posts: () => import("../utils/social/social_media_posts"),
    social_media_ads: () => import("../utils/social/social_media_ads"),
    analytics: () => import("../utils/social/analytics"),
  },
  data() {
    return {
      accounts,
      posts,
      advertising,
      marketing_analytics
    }
  },
  computed: {
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(ADMIN, ["user_edited_social_data", "feature_toggles"]),
    social_action_type() {
      return this.$route.params.social_action_type
    },
  },
  mounted() {
    // Dont fetch data if user viewing marketing analytics on store admin
    if (this.social_action_type === marketing_analytics && !is_user_in_global_admin()) return
    this.fetch_data()
  },
  methods: {
    ...mapActions(SOCIAL_STORE, {
      fetch_data: FETCH_SOCIAL_MEDIA
    }),
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .social-media {
    &__content {
      padding: $default-size;

      @media (max-width: $tablet) {
        padding: $half-default-size;
      }
    }
    
    &__left-aligned-button {
      margin: $default-size 0;
    }

    &__sub-title {
      margin-top: 0;
    }

    &__page {
      display: inline-block;
      margin: 0 $default-size $default-size 0 !important;
      padding:  0 $default-size !important;
      grid-template-columns: 1fr;
      column-gap: $default-size;
      align-items: middle;
      border: 1px solid transparent;
      margin-bottom: $default-size;
      cursor: pointer;
      border-radius: $border-radius;
      transition: $default-transition;
      background-color: transparent;
      overflow: hidden;

      &:last-child {
        margin: 0 0 $default-size !important;
      }

      @media (max-width: $tablet) {
        margin: 0 $half-default-size $half-default-size 0 !important;
        padding: 0 $half-default-size !important;

        &:last-child {
          margin: 0 0 $half-default-size !important;
        }
      }

      &--selected {
        box-shadow: none;
        border: 1px solid $green !important;
      }
    }

    &__platform {
      display: inline-block;
      margin: 0 $default-size $default-size 0;
      border-radius: $border-radius;
      padding: $half-default-size !important;

      @media (max-width: $tablet) {
        margin: 0 $half-default-size $half-default-size 0;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        cursor: pointer;

        @media (max-width: $tablet) {
          width: 50px;
          height: 50px;
        }
      }

      .md-button {
        display: block;
        margin: $half-default-size auto 0;
      }
    }

    &__admin-message {
      margin: $double-default-size auto;
      width: 100%;
      max-width: 500px;

      a {
        margin-left: $half-default-size;
      }
    }

    .emoji-mart {
        position: absolute;
        top: calc(100% + #{$default-size});
        left: 0;
        z-index: 3;

        @media (max-width: $mobile) {
          top: calc(100% + 20px);
          left: -80px;
          width: calc(100vw - 80px) !important;
          z-index: 3;
        }
      }
  }
</style>
